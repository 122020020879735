<template>
    <div class="k-card-3">
        <div class="k-flex-row flex-nowrap text-center">
            <div>
                <b-icon-layout-wtf class="text-kalkin-2 h4" />
            </div>
            <div>
                <h5 class="m-0">{{ moduleInfos.name }}</h5>
            </div>
            <div class="text-secondary">
                <b-badge
                    class="bg-danger d-block"
                    v-if="this.module.state === -1"
                >
                    obsolète
                </b-badge>

                <small v-if="this.module.version">
                    v.{{ this.module.version }}
                </small>
                <b-iconstack class="mr-1" v-else>
                    <b-icon stacked icon="clock" />
                    <b-icon
                        stacked
                        variant="kalkin-2"
                        icon="arrow-counterclockwise"
                        scale="2"
                    />
                </b-iconstack>
            </div>
        </div>

        <div class="font-italic text-justify py-2 px-3 rounded bg-light border">
            {{ moduleInfos.description || "Pas de description" }}
        </div>

        <div class="flex-grow-1" />

        <div class="k-flex-row k-flex-fill k-gap-10">
            <b-button
                v-if="displayAdd"
                class="text-nowrap"
                size="sm"
                variant="kalkin-2"
                @click="$emit('add', module)"
            >
                <b-icon-plus-circle /> Ajouter au projet
            </b-button>
            <b-button
                v-if="displayRemove"
                class="text-nowrap"
                size="sm"
                variant="outline-danger"
                @click="$emit('remove', module)"
            >
                <b-icon-dash-circle /> Retirer
            </b-button>
            <b-button
                v-if="displayForKalkin"
                class="text-nowrap"
                size="sm"
                variant="outline-kalkin-1"
                @click="$emit('configure', module)"
            >
                <b-icon-gear /> Administration
            </b-button>
        </div>

        <div class="k-flex-row k-flex-fill k-gap-10">
            <small
                v-if="displayContactUs"
                class="text-kalkin-3 text-center py-3"
            >
                <b-icon-info-circle-fill /> Nous contacter pour plus
                d'informations
            </small>
            <div v-if="displayForKalkin">
                <b-button
                    class="text-nowrap w-100"
                    size="sm"
                    variant="kalkin-2"
                    @click="$emit('consult', { module })"
                >
                    Vue Kalkin
                </b-button>
            </div>

            <b-dropdown
                v-if="displayForKalkin"
                class="text-nowrap"
                size="sm"
                right
                variant="kalkin-2"
                text="Vues membres"
            >
                <b-dropdown-item-button disabled v-show="!configs.length">
                    Aucune configuration
                </b-dropdown-item-button>
                <b-dropdown-item-button
                    v-for="config in configs"
                    :key="config.id"
                    @click="$emit('consult', { module, configId: config.id })"
                >
                    {{ config.name }}
                </b-dropdown-item-button>
            </b-dropdown>

            <b-button
                v-if="displayForMember"
                class="text-nowrap"
                size="sm"
                variant="kalkin-2"
                @click="$emit('consult', { module })"
            >
                Gérer
            </b-button>
        </div>
    </div>
</template>

<script>
import Modules from "@/model/modules";

const PUBLIC = 4;

export default {
    props: {
        projectId: { type: [String, Number], required: true },
        configs: { type: Array, default: () => [] },
        module: { type: Object, required: true },
        isAdmin: { type: Boolean, required: true },
        isUsed: { type: Boolean, required: true },
    },

    computed: {
        moduleInfos() {
            if (this.module.legacy) {
                return Modules.getModuleInfos(this.module.name);
            } else {
                return this.module;
            }
        },

        displayAdd() {
            return (
                !this.isUsed && (this.isAdmin || this.module.state >= PUBLIC)
            );
        },

        displayRemove() {
            return (
                this.isUsed &&
                (this.isAdmin || this.module.state >= PUBLIC) &&
                !this.module.legacy
            );
        },

        displayForKalkin() {
            return this.isUsed && this.isAdmin;
        },

        displayForMember() {
            return this.isUsed && !this.isAdmin;
        },

        displayContactUs() {
            return (
                !this.isUsed &&
                !this.isAdmin &&
                (this.module.state < PUBLIC || !this.module.state)
            );
        },
    },

    methods: {},
};
</script>
