<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>Modules du projet</h1>
        </div>

        <GridCardBasic :data="modules.used || []" empty="Aucun module actif">
            <template v-slot="{ data }">
                <CardProjectModule
                    :project-id="projectId"
                    :configs="configs"
                    :module="data"
                    :is-admin="true"
                    :is-used="true"
                    @configure="onConfigureClicked($event)"
                    @consult="onConsultClicked($event)"
                    @remove="onRemoveClicked($event)"
                />
            </template>
        </GridCardBasic>

        <h3 class="k-flex-row">
            Modules disponibles
            <div class="k-flex-line" />
        </h3>

        <GridCardBasic
            :data="modules.available || []"
            empty="Aucun module disponible"
        >
            <template v-slot="{ data }">
                <CardProjectModule
                    :project-id="projectId"
                    :module="data"
                    :is-admin="true"
                    :is-used="false"
                    @add="onAddClicked($event)"
                />
            </template>
        </GridCardBasic>

        <ModalDanger
            message="Voulez-vous vraiment retirer ce module?"
            details="Toute la configuration de celui-ci sera définitivement perdue."
            @confirmed="removeModule()"
        />
    </div>
</template>

<script>
import GridCardBasic from "@/components/ui/GridCardBasic";
import CardProjectModule from "@/components/model/projectmodule/CardProjectModule";
import ModalDanger from "@/components/modal/ModalDanger";

export default {
    components: {
        GridCardBasic,
        CardProjectModule,
        ModalDanger,
    },

    data: function() {
        return {
            projectId: this.$route.params.projectId,
            modules: {},
            configs: [],
            selectedModule: null,
        };
    },

    methods: {
        async fetchModules() {
            this.modules = await this.$store.dispatch(
                "admin/getProjectModules",
                { projectId: this.projectId },
            );
        },

        async fetchConfigs() {
            this.configs = await this.$store.dispatch(
                "application/getApplicationConfigs",
                { projectId: this.projectId },
            );
        },

        async onConfigureClicked(module) {
            this.$router.push({
                name: `AdminProjectModuleConfig`,
                params: {
                    projectId: this.projectId,
                    moduleId: module.id,
                },
            });
        },

        async onConsultClicked({ module, configId }) {
            if (configId) {
                this.$router.push({
                    name: `ProjectModule`,
                    params: {
                        projectId: this.projectId,
                        moduleId: module.id,
                        configId,
                    },
                });
            } else {
                this.$router.push({
                    name: `AdminProjectModule`,
                    params: {
                        projectId: this.projectId,
                        moduleId: module.id,
                    },
                });
            }
        },

        async onAddClicked(module) {
            await this.$store.dispatch("admin/createProjectModule", {
                projectId: this.projectId,
                moduleId: module.id,
            });
            await this.fetchModules();
        },

        async onRemoveClicked(module) {
            this.selectedModule = module;
            this.$bvModal.show("ModalDanger");
        },

        async removeModule() {
            await this.$store.dispatch("admin/deleteProjectModule", {
                projectId: this.projectId,
                moduleId: this.selectedModule.id,
            });
            this.selectedModule = null;
            await this.fetchModules();
        },
    },

    async beforeMount() {
        await this.fetchModules();
        await this.fetchConfigs();
    },
};
</script>
